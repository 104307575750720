@import '../../index.scss';
.leftMenu {max-width: 250px; margin: 0;
    @include respond-to(media-md) {
        display: flex; max-width: 100%;
    }
    li {margin-bottom: 5px; font-size: 20px; font-weight: 600;
        @include respond-to(media-lg) {
            font-size: 17px;
        }
        @include respond-to(media-md) {
           margin: 0 5px 0 0; font-size: 16px;
        }
        a {display: block; color: #ffff; background: #2f75b5; border-radius: 4px; padding: 12px 25px;
            @include respond-to(media-md) {
                padding: 8px 15px;
             }
            &:hover, &.active {background: #002841;}
        }
    }
}