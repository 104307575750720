@import '../../mixin.scss';
.modalOuter {
    &.accountForm {max-width: 1000px !important;}
} 

.accountTables {
    table {
        tbody {
            td{vertical-align: top;
                &.id {width:10%; white-space: nowrap;}
                &.answer {width: 90%;}
            }
        }
    }
}