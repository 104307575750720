@import '../../mixin.scss';
.borBot {border-bottom: 1px solid #e3e3e3;}
.labelList {
    .left {width: 180px;
        @include respond-to(media-md) {
            width: 180px;
        }
        @include respond-to(media-sm) {
            width: 100%;
        }
        .hd {font-size: 22px; font-weight: 600; padding-bottom: 20px;
            @include respond-to(media-xl) {
                font-size: 20px;
            }
            @include respond-to(media-md) {
                font-size: 18px; padding-bottom: 10px;
            }
        }
    }
    .right {width: calc(100% - 180px); padding: 0 0 0 60px;
        @include respond-to(media-md) {
            width: calc(100% - 180px);
        }
        @include respond-to(media-sm) {
            width: 100%; padding: 20px 0 0 0;
        }
    }
}
.labelDet {
    li {border-bottom: 1px solid #e4e4e4; padding: 20px 0; font-size: 17px; font-weight: 500;
        @include respond-to(media-xl) {
            font-size: 15px;
        }
        @include respond-to(media-md) {
            font-size: 14px; padding: 15px 0; display: block !important;
        }
        &:last-child {border-bottom: none; padding-bottom: 0;}
        &:first-child {padding-top: 0;}
        .date {width: 150px;
            @include respond-to(media-md) {
                width: 100%;
            }
        }
        .det {padding: 0 20px; width: calc(100% - 320px);
            &.large {width: calc(100% - 320px);
                @include respond-to(media-md) {
                    width: 100%;
                }
            }
            @include respond-to(media-md) {
                width: 100%; padding: 5px 0 0 0;
            }
        }
        .link {width: 320px;
            &.large {width: 320px;
                @include respond-to(media-md) {
                    width: 100%;
                }
            }
            @include respond-to(media-md) {
                width: 100%; padding: 5px 0 0 0;
            }
            a {display: inline-block; cursor: pointer; position: relative; padding-right: 20px; margin: 0 20px 0 0px;
                &:after {content: ''; background: url(../../assets/images/aroow.png) no-repeat right center; width: 16px; height: 8px; position: absolute; top: 50%; margin-top: -3px; right: 0;}
                &:hover {padding-right: 25px;}
            }
        }
    }
}

@media (min-width: 1200px) {
    #root {
        .pt-xl-10 {
            padding-top: 6rem !important;
        }
        .pb-xl-10 {
             padding-bottom: 6rem !important;
            
        }
    }    
}