@import '../../mixin.scss';
.reportListTable {
    table {
        tbody {
            td{vertical-align: top;
                &.date {width: 12%; white-space: nowrap;}
                &.format {width: 16%; min-width: 180px;}
                &.title {width: 30%; min-width: 240px;
                    span {display: block; font-size: 19px; font-weight: 600;
                        @include respond-to(media-xl) {
                            font-size: 17px;
                        }
                        @include respond-to(media-sm) {
                            font-size: 16px;
                        }
                    }
                }
                &.page {width: 30%; min-width: 180px; min-width: 200px;}
                &.edit {width: 12%; white-space: nowrap; min-width: 145px;
                    a {color: #2f75b5; text-decoration: underline;
                        &:hover {text-decoration: none; color: #2f75b5;}
                    }
                }
            }
        }
    }
}