@import '../../index.scss';
.notiBtn {
    &.btn {padding: 0; background: none !important; border: none !important; box-shadow: none !important;
    &:after {display: none;}
    &:hover {background: none;}
    }
}
.notiBox {
    .dropdown-menu {right: -23px !important; left: auto !important; margin: 23px 0 0 0; padding: 0; width: 200px; transform: initial !important; top: 100% !important;
        &:before {content: ''; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid #eee; position: absolute; right: 28px; top: -8px;}
        .dropdown-item {border-bottom: 1px solid #eee; padding: 8px 15px;
            &:last-child {border: none;}
            .count {background: red; width: 20px; height: 20px; color: #fff; border-radius: 100%; font-size: 12px; text-align: center; line-height: 20px;}
        }
    }
}

.notificationTotalcount {position: absolute; right: -10px; top: -10px; background: red; width: 20px; height: 20px; border-radius: 100%; color: #fff; font-size: 11px; text-align: center; line-height: 20px;}
