@import '../../mixin.scss';
.pdfImg {
    iframe {width: 100%;}
   
}
.reportTop {
    margin-top:30px ;

    .date {font-size: 18px; font-weight: 400; line-height: 16px; 
        @include respond-to(media-xl) {
            font-size: 16px;
        }
        @include respond-to(media-sm) {
            font-size: 14px;
        }
    }
    .mainHd {font-size:21px ;padding: 10px 0 0 0px}
}

.reportBox {position: relative; padding: 0 0 0 85px;
    @include respond-to(media-md) {
        padding: 0;
    }
    .reportIcons {position: absolute; left: 0; top: 0;
        @include respond-to(media-md) {
            position: static;
        }
        ul {
            @include respond-to(media-md) {
               display: flex; margin: 0;
            }
            li {margin-bottom: 20px;
                @include respond-to(media-md) {
                    margin-right: 10px;
                 }
            }
        }
    }
}
.reportBtns {margin: 0 0 15px 0;
    li {margin: 0 10px 10px 0;
        a {display: block; background: #2f75b5; font-size: 18px; color: #fff; border-radius: 4px; padding: 10px 40px;
            &:hover {background: #000;}
            @include respond-to(media-md) {
                font-size: 16px; padding: 8px 15px;
             }
        }
    }
}
.reportHd {font-size: 28px; color: #2f75b5; padding: 0 0 10px 0;
    @include respond-to(media-xl) {
        font-size: 24px;
    }
    @include respond-to(media-sm) {
        font-size: 20px;
    }
}
.fw600 {font-weight: 600;}
.bulletList {margin: 0; padding: 0 0 0 16px;
    li {position: relative; padding: 4px 0; list-style:disc;}
}
.lineSep {letter-spacing: 4px;}

.rp_li_style ul {    
    padding-left: 20px !important;
    li {
        list-style-type: disc !important;
    }
}
.rp_li_style ol {
    padding-left: 20px !important;
    li {
        list-style-type: decimal !important
    }
}