.lrtListBx {

    margin-bottom: 90px;
    border-radius: 6px;
    border: 1px solid #d0cece;
    color: #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    h2 {
        background: #2f75b5;
        font-size: 20px;
        padding: 10px;
        color: #fff;
        font-weight: 600;

    }

    ul {
        margin: -10px;
        padding: 20px 30px;
        display: flex;
        justify-content: start;

        li {

            padding: 10px;
            text-align: center;
            // width:20%;
            max-width: 16.90%;
            margin: 0 2%;

            a {
                color: #000;

                i {
                    display: block;
                }

                strong {
                    display: block;
                    font-size: 18px;
                    padding: 10px 0 0px 0;

                }

                span {
                    display: block;
                    font-size: 16px;
                }
            }
        }
    }
}

.customimageSize {
    width: 300px;
}