@import '../../mixin.scss';
.profileTop {position: relative; padding: 0 150px 0 0;
    @include respond-to(media-xl) {
        padding: 0 120px 0 0;
     }
     @include respond-to(media-lg) {
        padding: 0;
     }
     .mainHd {padding: 0 0 5px 0;}
     .text {font-size: 20px;
        @include respond-to(media-xl) {
            font-size: 17px;
        }
    } 
    .logoutBtn {position: absolute; right: 0; cursor: pointer; bottom: 0; background: #2f75b5; display: inline-block; width: 150px; border-radius: 4px; padding: 12px 5px; font-size: 18px; color: #fff; text-align: center;
        @include respond-to(media-xl) {
            font-size: 16px; padding: 10px 5px; width: 120px;
         }
         @include respond-to(media-lg) {
            position: static; margin-top: 15px;
        }
        @include respond-to(media-md) {
            position: absolute; margin-top: 0;
        }
         &:hover {background: #000;}
    }
}
.contactInfo {font-size: 19px; margin: 20px 0 0 0;
    @include respond-to(media-xl) {
        font-size: 16px;
    }
    span {display: block; font-weight: 600; padding-bottom: 2px;}
    a {text-decoration: none; color: #000;
        &:hover {text-decoration: none;}
    }
}
.profileInfo {
    a {color: #2f75b5;}
}

.profileHd {
     .mainHd {padding: 0 0 5px 0;}
     .text {font-size: 20px;
        @include respond-to(media-xl) {
            font-size: 17px;
        }
    } 
}
.profileDetCon {border: 1px solid #d0cece; border-radius: 6px; padding: 20px;
    @include respond-to(media-md) {
        padding: 15px;
    }
}

button.formbtn {background: #2f75b5; color: #fff; font-size: 20px; padding: 10px 40px;
    @include respond-to(media-xl) {
        font-size: 17px; padding: 10px 30px;
    }
    @include respond-to(media-sm) {
        font-size: 15px;
    }
    &:hover {background: #000;}
}

.profileBot {border-top:1px solid #bbbbbb; padding: 50px 0; margin-top: 30px;
    @include respond-to(media-xl) {
        padding: 30px 0; margin-top: 10px;
    }
    @include respond-to(media-md) {
        padding: 20px 0; margin-top: 10px;
     }
}
.profileBtns {margin: 20px 0 0 0;
    li {margin: 0 10px 15px 0;
        a {display: block; background: #2f75b5; font-size: 18px; color: #fff; border-radius: 4px; padding: 10px 40px;
            &:hover {background: #000;}
            @include respond-to(media-md) {
                font-size: 16px; padding: 8px 15px;
             }
        }
    }
}