@import '../../mixin.scss';
.passwordIn {font-family: Arial, Helvetica, sans-serif !important; font-weight: 700;}
.modalOuter.modal-dialog {max-width:800px !important; font-family: 'univers-light'; min-height: 100%; display: flex; align-items: center; margin: auto;
    .loginInside {max-width: 600px; width: 100%; margin: auto;}
    .modal-content {border: none; background: none; padding: 10px;}
    .modal-header {margin: 0; padding: 0; position: relative; background: #fff; border: none; border-radius: 6px 6px 0 0; border-bottom: 1px solid #b7b7bc;
        .modal-title {position: relative; z-index: 2; font-size: 25px; color: #2f75b5; padding: 15px 30px; font-weight: 600;
            @include respond-to(media-xl) {
                font-size: 22px; padding: 12px 30px; 
            }
            @include respond-to(media-sm) {
                font-size: 18px; padding: 10px 15px; 
            }
        }
        .close {padding: 0; margin: 0; position: absolute; right: 30px; top: 25px; opacity: 1; z-index: 10; width: 16px; height: 16px; background: url(../../assets/images/close_icon.png) no-repeat;
            @include respond-to(media-sm) {
                right: 15px; top: 15px;
            }
            span {display: none;}
        }
    }
    .modal-body {background: #fff; border-radius: 0 0 6px 6px; padding: 0;
        .inner {padding: 30px;
            @include respond-to(media-sm) {
                padding: 15px;
            }
        }
        .form-group {margin-bottom: 20px;
            @include respond-to(media-sm) {
                margin-bottom: 10px;
            }
            label {font-size: 19px;
                @include respond-to(media-xl) {
                    font-size: 16px;
                }
                @include respond-to(media-sm) {
                    font-size: 14px; margin-bottom: 5px;
                }
            }
            .loginInput {padding: 12px 15px; height: auto; font-size: 18px;
                @include respond-to(media-xl) {
                    font-size: 16px; padding:10px 15px;
                }
            }
            &.mb0 {margin-bottom: 0;}
        }
        .loginLink {font-size: 19px;
            @include respond-to(media-xl) {
                font-size: 16px;
            }
            @include respond-to(media-sm) {
                font-size: 14px;
            }
            a {color: #2f75b5;
                &:hover {color: #000;}            }
        }
        .loginBtn {border-top: 1px solid #b7b7bc; padding: 30px; text-align: right; display: flex; justify-content: space-between; align-items: center;
            
            @include respond-to(media-sm) {
                padding: 15px;
            }
            .mesggtext {text-align: left; padding-right: 10px;}
            button {background: #2f75b5; white-space: nowrap; color: #fff; font-size: 20px; padding: 10px 40px;
                @include respond-to(media-xl) {
                    font-size: 17px; padding: 10px 30px;
                }
                @include respond-to(media-sm) {
                    font-size: 15px;
                }
                &:hover {background: #000;}
            }
        }
        .regForm {margin: -10px; 
            @include respond-to(media-xs) {
                margin: -5px;
            }
            &.checkForm {
                li {
                @include respond-to(media-xs) {
                    padding: 5px; width: 50%;
                }
            }
            }
            li {width: 50%; padding: 10px;
                @include respond-to(media-xs) {
                    padding: 5px; width: 100%;
                }
                .form-group {margin-bottom: 0;}
                .regInput {padding: 12px 15px; border: 1px solid #b3b3b3; background: #D0CECE; height: auto; font-size: 18px; color: #000;
                    @include respond-to(media-xl) {
                        font-size: 16px; padding:10px 15px;
                    }
                }
                .countInput {border: 1px solid #858585; width: 80px; font-size: 18px; height: auto; padding: 7px 5px; text-align: center; border-radius: 10px; display: inline-block;}
                .optnText {display: inline-block; margin: 0 0 0 15px; color: #322222; font-weight: 400;}
            }
        }
        .regSubHd {font-size: 19px; display: block; font-weight: 600; padding-bottom: 10px;
            @include respond-to(media-xl) {
                font-size: 17px;
            }
            @include respond-to(media-xs) {
                font-size: 15px;
            }
        }
        select.form-control {border: 1px solid #b3b3b3; height: auto; border-radius: 6px; padding: 12px; font-size: 18px; appearance: none; background: #fff url(../../assets/images/select_arrow.png) no-repeat right 15px center;}
    }
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {opacity: 1;}
.checkCover {position: relative; padding: 0 0 0 20px; margin: 0;
    .checkWrapper {position: absolute; left: 0; top: 2px;}
}
.tcText {display: inline-block; position: relative; margin-bottom: 20px; font-size: 19px;
    @include respond-to(media-xl) {
        font-size: 17px;
    }
    @include respond-to(media-xs) {
        font-size: 15px;
    }
    a {text-decoration: underline; display: inline-block;
        &:hover {text-decoration: none;}
    }
}
.asterick{
    color: #ff0000;

}

.errorMsg {
    color: #ff0000;
    background: none;
    font-size: 16px;
    margin: 0;
    text-align:left;
}
.successMsg {

    color: #270;
    background: none;
    padding: 5px 0 0 0;
    font-size: 16px;
    margin: 0;

}

.alert-success{
    text-align: center;

}
.alert-danger{

    text-align: center;  
}
.fullWidth {width:100%;}