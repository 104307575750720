@import '../../mixin.scss';
.mainHd {font-size: 33px; padding-bottom: 10px; font-weight: 700;
    @include respond-to(media-xl) {
        font-size: 28px;
    }
    @include respond-to(media-sm) {
        font-size: 22px;
    }
}

.clientRes {margin: 0; border: 1px solid #d0cece; border-radius: 4px; max-width: 500px;
    li {border-bottom: 1px solid #d0cece; font-size: 18px; font-weight: 600;
        @include respond-to(media-xl) {
            font-size: 16px;
        }
        @include respond-to(media-sm) {
            font-size: 14px;
          }
        &:last-child {border: none;}        
        a {color: #000; display: block; padding: 20px 30px;
            @include respond-to(media-md) {
                padding: 15px 20px;
            }
            &:hover {color: #2f75b5;}
        }
    }
}

.portalList {margin: -25px;
    @include respond-to(media-lg) {
        margin: -15px;
    }
    @include respond-to(media-md) {
        margin: -10px;
    }
    li {padding: 25px;
        @include respond-to(media-lg) {
            padding: 15px;
        }
        @include respond-to(media-md) {
            padding: 10px;
        }
        .inner {border-radius: 4px 4px 0 0; height: 100%; box-shadow: 0 0 15px rgba(0,0,0,.1); padding: 25px; border-bottom: 8px solid transparent;
            @include respond-to(media-md) {
                padding: 15px;
            }
            .hd {font-size: 22px; font-weight: 600; line-height: normal;
                @include respond-to(media-xl) {
                    font-size: 20px;
                }
                @include respond-to(media-md) {
                    font-size: 18px;
                }
            }
            a.viewLink {display: inline-block; color: #000; padding: 0 35px 0 0; background: url(../../assets/images/black_arrow.png) no-repeat right center; font-weight: 500;
                &:hover {padding-right: 45px;}
            }
        }
    }
}

.resList {margin: -30px;
    @include respond-to(media-lg) {
        margin: -15px;
    }
    @include respond-to(media-md) {
        margin: -10px;
    }
    li {padding: 30px 30px 10px;
        @include respond-to(media-lg) {
            padding: 15px;
        }
        @include respond-to(media-md) {
            padding: 10px;
        }
        .inner {height: 100%; padding: 0 45px; border-bottom: 8px solid transparent;
            @include respond-to(media-md) {
                padding: 15px;
            }
            .hd {font-size: 22px; font-weight: 600; line-height: normal;
                @include respond-to(media-xl) {
                    font-size: 20px;
                }
                @include respond-to(media-md) {
                    font-size: 18px;
                }
            }
            a.viewLink {display: inline-block; color: #000; padding: 0 35px 0 0; background: url(../../assets/images/black_arrow.png) no-repeat right center; font-weight: 500;
                &:hover {padding-right: 45px;}
            }
        }
    }
}

.categoryList {margin: -40px -25px; padding-top: 0px;
    @include respond-to(media-lg) {
        margin: -15px;
    }
    @include respond-to(media-md) {
        margin: -10px; padding-top: 0;
    }
    li {padding: 40px 70px;
        @include respond-to(media-lg) {
            padding: 15px;
        }
        @include respond-to(media-md) {
            padding: 10px;
            margin-top: 10px;
        }
        .inner {
            .hd {font-size: 22px; font-weight: 600; line-height: normal; position: relative;
                &:before {content: ''; background: #2f75b5; width: 35px; height: 4px; position: absolute; left: 0; top: -10px; border-radius: 6px;}
                a {color: #000;
                    &:hover {color: #2f75b5;}
                }
                @include respond-to(media-xl) {
                    font-size: 20px;
                }
                @include respond-to(media-md) {
                    font-size: 18px;
                }
            }
        }
    }
}
.textHeight{
    min-height: 90px !important;
}