@import '../../mixin.scss';
.manualForm {margin: -15px;
    @include respond-to(media-lg) {
        margin: -5px; padding-bottom: 10px;
    }
    li {width: 33.33%; padding: 15px;
        @include respond-to(media-lg) {
            padding: 5px;
        }
        @include respond-to(media-md) {
            width: 38%;
        }
        @include respond-to(media-xs) {
            width: 50%;
        }
        &.coumn18 {width: 18%;
            @include respond-to(media-md) {
                width: 24%;
            }
            @include respond-to(media-xs) {
                width: 50%;
            }
        }
        &.coumn23 {width: 23%;
            @include respond-to(media-md) {
                width: 38%;
            }
            @include respond-to(media-xs) {
                width: 50%;
            }
        }
        &.fullWidth {width: 100%;}        
        .form-group {margin-bottom: 5px;
            @include respond-to(media-sm) {
                margin-bottom: 0px;
            }
        }        
    }
}

.form-group {
    label {font-size: 19px; font-weight: 600; margin-bottom: 8px; display: block;
        @include respond-to(media-xl) {
            font-size: 17px;
        }
        @include respond-to(media-sm) {
            font-size: 14px;
        }
    }
    .formInput.form-control {width: 100%; height: auto; border: 1px solid #b3b3b3; border-radius: 4px; padding: 12px 20px; font-size: 17px;
        @include respond-to(media-xl) {
            padding: 10px 15px; font-size: 16px;
        }
        @include respond-to(media-sm) {
            padding: 8px 12px; font-size: 14px;
        }
        
        &.textarea {height: 200px;
            @include respond-to(media-xl) {
                height: 150px;
            }
            @include respond-to(media-sm) {
                height: 100px;
            }
        }
    }
    select.form-control {border: 1px solid #b3b3b3; height: auto; border-radius: 6px; padding: 12px; font-size: 18px; appearance: none; background: #fff url(../../assets/images/select_arrow.png) no-repeat right 15px center;
        @include respond-to(media-xl) {
            padding: 10px 15px; font-size: 16px;
        }
        @include respond-to(media-sm) {
            padding: 8px 12px; font-size: 14px; background-size: 10px; background-position: right 10px center;
        }
    }

 

li.formbtn {
  display: inline-block;
  width: auto;
  padding: 0 20px;
  background-color: #777;
  font-size: 14px;
}
.latestReportForm li{
    width :27.33%;
}


}