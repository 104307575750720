@import '../../mixin.scss';
.ocioRes {margin: 0; border-radius:0 0 4px 4px;
    li {border-bottom: 1px solid #d0cece; font-size: 18px; font-weight: 600;
        @include respond-to(media-xl) {
            font-size: 16px;
        }
        @include respond-to(media-sm) {
            font-size: 14px;
          }
        &:last-child {border: none;}        
        a {color: #000;cursor: pointer; display: block; cursor: pointer; padding: 20px 30px;
            @include respond-to(media-md) {
                padding: 15px 20px;
            }
            &:hover {color: #2f75b5;}
        }
    }
}
.blueClr {color: #2f75b5 !important;}
.ocioList {margin: -15px;
    li {width: 50%; max-width: 630px; padding: 15px; align-items: flex-start;
        @include respond-to(media-xl) {
            max-width: 600px;
        }
        @include respond-to(media-sm) {
            width: 100%;
        }
        .img {width: 280px; padding: 0 50px; 
            @include respond-to(media-xl) {
                width: 200px; padding: 0 30px; 
            }
            @include respond-to(media-md) {
                width: 180px;
            }
            // img {box-shadow: 0 0 10px rgba(0,0,0,.2);}
        }
        .det {width: calc(100% - 280px); padding: 0; font-size: 17px; line-height: 28px;
            @include respond-to(media-xl) {
                width: calc(100% - 200px); font-size: 15px; line-height: 26px;
            }
            @include respond-to(media-md) {
                width: calc(100% - 180px); font-size: 14px; line-height: 24px;
            }
            span {display: block; font-size: 22px; font-weight: 600; line-height: normal; padding-bottom: 15px;
                @include respond-to(media-xl) {
                    font-size: 20px;
                }
                @include respond-to(media-md) {
                    font-size: 18px; padding-bottom: 10px;
                }
            }
        }
    }
}

.caaTable, .taaTable, .etfTable {margin: 0 50px;
    @include respond-to(media-xl) {
        margin: 0 30px;
    }
}
