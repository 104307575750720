.resorcesHd {
    max-width: 500px;
    padding: 0 0 32px 0;
    

    h2 {
        padding-bottom: 10px;
        font-weight: 700;
        font-size: 33px;
        color: #000;
    }

    p {
        // font-size: 16px;
        // line-height: 25px;
        font-size: 18px;
        line-height: 26px;
        color: #000;
    }
}

.platfrmRes {
    background: #2f75b5;
    padding: 10px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 15px;
    
}

.resourceTable {
    
    table {
        width: 100%;

        th {
            border-bottom: #ccc solid 1px;
            padding: 10px;
            font-size: 15px;
        }

        td {
            border-bottom: #ccc solid 1px;
            padding: 10px;
            font-size: 15px;

            a {
                // color: #0099ff;
                color:#2f75b5;

            }

        }
    }
}

.resourceTable .viewLink {
    a {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-right: 20px;
        margin: 0 20px 0 0px;

        &:after {
            content: '';
            background: url(../../assets/images/aroow.png) no-repeat right center;
            width: 16px;
            height: 8px;
            position: absolute;
            top: 50%;
            margin-top: -3px;
            right: 0;
        }

        &:hover {
            padding-right: 25px;
        }
    }

}

.resourceTable {
    margin: 0 30px;
}
.resources{
    .platfrmRes{
        margin-bottom: 0px;
    }

    table thead{
        background: transparent !important;
        box-shadow: none !important;
        th{
            background: transparent !important;
            color: #000;
        }
    }
}