@import '../../mixin.scss';
.dateRange {display: inline-block; margin:0 10px 15px 0;
    &.ml20 {margin-left: 20px;
        @include respond-to(media-sm) {
            margin-left: 10px;
          }
    }
}
.dateRangeInput {max-width: 170px;
    @include respond-to(media-md) {
        width: 140px;
      }
      @include respond-to(media-sm) {
          width: 100px;
        }
}
.analyticsTable {
    table {
        tbody {
            td{vertical-align: top;
                &.id {width: 8%;}
                &.date {width:10%; white-space: nowrap;}
                &.bookmark {width: 20%; min-width: 160px;}
                &.watchlist {width: 20%; min-width: 160px;}
                &.visited {width: 20%; min-width: 160px;}
                &.history {width: 11%; min-width: 120px;}
                &.following {width: 11%; min-width: 120px;}
            }
        }
    }
}