@import '../../mixin.scss';
.editForm {margin: -10px -15px; max-width: 1100px;
    @include respond-to(media-lg) {
        margin: -5px; padding-bottom: 10px;
    }
    li {width: 50%; padding: 10px 15px;
        @include respond-to(media-lg) {
            padding: 5px;
        }
        &.fullWidth {width: 100%;}        
        .form-group {margin-bottom: 5px;
            @include respond-to(media-sm) {
                margin-bottom: 0px;
            }
            .formInput.reportTA {height: 400px;
                @include respond-to(media-xl) {
                    height: 350px;
                }
                @include respond-to(media-md) {
                    height: 150px;
                }
            }
            .updateTA {height: 300px;
                @include respond-to(media-xl) {
                    height: 250px;
                }
                @include respond-to(media-md) {
                    height: 100px;
                }
            }
        }
    }
}