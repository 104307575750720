@import '../../mixin.scss';
.libBox {padding: 20px 20px 30px 20px;}
.mtM {margin-top: -10px;}
.form-control.libSearch {font-family: 'univers-light'; padding: 12px 12px 12px 45px; width: 100%; height: auto; border:1px solid #a9a9a9; background: #fff url(../../assets/images/gray_search.png) no-repeat 10px center;
    @include respond-to(media-sm) {
        padding: 8px 8px 8px 35px; font-size: 16px; background-position: 8px center;
    } 
}
.libOptList {padding: 15px 20px 25px 20px; margin: 0;
    li {padding: 8px 0;
        .form-group {margin: 0;}
        label {position: relative; padding: 0 0 0 35px; margin: 0; font-weight: 400;
            .checkWrapper {position: absolute; left: 0; top: 2px;}
        }
    }
}

.libTable {
    table {
        tbody {
            td{vertical-align: top;
                &.date {width: 12%; white-space: nowrap;}
                &.format {width: 16%; min-width: 180px;}
                &.report {width: 58%; min-width: 220px;
                    span {display: block; font-size: 19px; font-weight: 600;
                        @include respond-to(media-xl) {
                            font-size: 17px;
                        }
                        @include respond-to(media-sm) {
                            font-size: 16px;
                        }
                    }
                }
                &.save {width: 14%; white-space: nowrap; min-width: 165px;
                    .action { 
                        @include respond-to(media-sm) {
                            width: 100%; padding: 10px 10px 10px 10px;
                          }
                        a {display: inline-block; margin: 0 30px 0 0;
                            @include respond-to(media-sm) {
                                margin: 0 15px 0 0;
                              }
                        }
                    }
                }
            }
        }
    }
}
.loadMore {color: #2f75b5; font-size: 22px;cursor: pointer;
    @include respond-to(media-sm) {
        font-size: 17px;
      }
    .spinner-border {vertical-align: middle;}
    .text {display: inline-block; vertical-align: middle; margin: 0 0 0 10px;}
}

.loadErrorMsg{

    
    margin: 0 0 0 10px;
    color: #ff0000;
    background: none;
    font-size: 16px;
    margin: 0;
    text-align:center
  


}