@import '../../mixin.scss';
.etfTable {
    table {
        tbody {
            td{
                &.date {width: 12%; white-space: nowrap;}
                &.title {width: 60%; font-weight: 600;}
                &.ytd {width: 14%; white-space: nowrap;}
                &.year {width: 14%; white-space: nowrap;}
            }
        }
    }
}