@import '../../index.scss';
.header {background: #2f75b5;}
.header .container {height: 80px;
    @include respond-to(media-sm) {
        height: auto;
    }
}
.logo {
    @include respond-to(media-md) {
        max-width: 220px;
    }
    @include respond-to(media-sm) {
        max-width: inherit; width: 100%; padding: 10px; text-align: center;
        img {width: 240px;}
    }
}
.headerRgt {
    @include respond-to(media-sm) {
        width: 100%; padding-bottom: 10px;
    } 
}
.form-control.searchInput {font-family: 'univers-light'; padding: 10px 10px 10px 45px; width: 340px; height: auto; border:none; background: #fff url(../../assets/images/search_icon.png) no-repeat 12px center;
    @include respond-to(media-md) {
        width: 280px;
    }
    @include respond-to(media-sm) {
        width: 200px; padding: 8px 8px 8px 35px; font-size: 16px; background-position: 8px center;
    } 
}
.topLink {margin: 0 0 0 10px;
    li {margin-left: 25px;
        @include respond-to(media-md) {
            margin-left: 15px;
        }
        img {
            @include respond-to(media-md) {
                width: 28px;
            }
            @include respond-to(media-sm) {
                width: 24px;
            }
        }
        .bookmark_count {position: absolute; right: -10px; top: -10px; background: red; width: 20px; height: 20px; border-radius: 100%; color: #fff; font-size: 11px; text-align: center; line-height: 20px;}
    }
}