@import '../../index.scss';
.topLinks {background: #f2f2f2;}
.topLinks .container {height: 55px;
    @include respond-to(media-sm) {
        height: auto; padding: 10px 15px;
    }
}
.menu {margin: 0;
    li {font-size: 17px; margin-right: 50px; font-weight: 600; padding: 4px 0;
        @include respond-to(media-sm) {
            font-size: 15px; margin-right: 30px;
        }
        &:last-child {margin-right: 0;}
        a {color: #000;
            &:hover, &.active {text-decoration: none; color: #2f75b5;}
        }
    }
    &.afterLogin {
        li {
            a {color: #757171;
                &:hover, &.active {text-decoration: none; color: #2f75b5;}
            }
        }
    }
}
.trialEnd {color: #ff3333; font-weight: 600;
    @include respond-to(media-sm) {
        font-size: 15px;
    }
    a {color: #ff3333;
        &:hover {color: #000;}
    }
}