@import '../../index.scss';
.footer {background: #2f75b5; margin-top: 40px;}
.footer .container {height: 80px;
    @include respond-to(media-sm) {
        height: auto;
    }
}
.footerLogo {
    @include respond-to(media-md) {
        max-width: 220px;
    }
    @include respond-to(media-sm) {
        max-width: inherit; width: 100%; padding: 10px; text-align: center;
        img {width: 240px;}
    }
}
.footerLink {margin: 0;
    @include respond-to(media-sm) {
        width: 100%; justify-content: center; text-align: center;
    }
    li {font-size: 17px; color: #fff; position: relative; margin-right: 40px; line-height: normal;
        @include respond-to(media-xl) {
            font-size: 16px;
        }
        @include respond-to(media-md) {
            font-size: 15px; margin-right: 20px;
        }
        &:last-child {margin-right: 0;
            &:after {display: none;}
            @include respond-to(media-sm) {
                width: 100%; padding: 10px 0;
            }
        }
        &:nth-last-child(2) {            
            @include respond-to(media-sm) {margin-right: 0;}
            &:after {
            @include respond-to(media-sm) {display: none;}
            }
        }
        &:after {content: ''; width: 1px; height: 12px; background: #fff; position: absolute; right: -22px; top: 3px;
            @include respond-to(media-md) {
                right: -12px;
            }
        }
        a {color: #fff;
            &:hover {color: #002841; text-decoration: none;}
        }
    }
}
.flex-wrap li {
    cursor: alias;
}