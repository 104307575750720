@import '../../mixin.scss';
.userListTable {
    table {
        tbody {
            td{vertical-align: top;
                &.date {width:8%; white-space: nowrap;}
                &.id {width: 8%;}
                &.firstName {width: 8%;}
                &.LastName {width: 8%;}
                &.email {width: 10%;}
                &.mobile {width: 10%;}
                &.firm {width: 12%; min-width: 120px;}
                &.status {width: 13%; min-width: 200px;}
                &.trialDate {width: 13%; min-width: 200px;}
                &.Action {width: 10%;}
            }
        }
    }
}