@font-face {
  font-family: 'univers-light';
  src: url('./assets/fonts/univers-light.woff') format('woff'),
       url('./assets/fonts/univers-light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@import './mixin.scss';
* {margin: 0; padding: 0; list-style: none; box-sizing: border-box;}
ul {margin: 0; padding: 0; list-style: none;}
img {max-width: 100%;}
body {
  margin: 0;
  font-family: 'univers-light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  line-height: normal;
  color: #000;
  font-size: 18px;
  @include respond-to(media-xl) {
    font-size: 16px;
  }
  @include respond-to(media-sm) {
    font-size: 14px;
  }
}
div {color: #000;}
.contentBox {font-size: 18px; line-height: 30px; color: #000;
  @include respond-to(media-xl) {
      font-size: 16px; line-height: 26px;
  }
  @include respond-to(media-sm) {
      font-size: 14px; line-height: 24px;
  }
}
.miidlePart {min-height: calc(100vh - 215px); color:#000;}
a {outline:none; text-decoration:none; color:#2f75b5; -webkit-transition:ease-in-out .3s; -moz-transition:ease-in-out .3s; -ms-transition:ease-in-out .3s; -o-transition:ease-in-out .3s; transition:ease-in-out .3s;
  &:hover {text-decoration: none !important; color: #000;}
}
code {
  font-family: 'univers-light';
}
.container {max-width: 1920px !important; margin: auto; padding: 0 120px !important;
  @include respond-to(media-xl) {
    padding: 0 60px !important;
  }
  @include respond-to(media-lg) {
    padding: 0 15px !important;
  }
}
.blueBgHd {background: #2f75b5; color: #fff; font-size: 24px; padding: 10px 20px; border-radius:6px 6px 0 0;
  @include respond-to(media-xl) {
    font-size: 20px;
  }
  @include respond-to(media-sm) {
    font-size: 18px; padding: 10px;
  }
}
.infoBox {border: 1px solid #d0cece; border-radius:0 0 6px 6px; box-shadow: 0 0 10px rgba(0,0,0,.3);}

.tableBox {border-radius: 6px; border: 1px solid #d0cece; overflow-x: auto; color:#000; box-shadow: 0 0 10px rgba(0,0,0,.3);
  table {width: 100%;
    thead {background: #2f75b5; box-shadow: 0 0 0 1px #2f75b5;
      th {font-weight: 400; color: #fff; font-size: 24px; padding: 10px 20px;
        @include respond-to(media-xl) {
          font-size: 20px;
        }
        @include respond-to(media-lg) {
          font-size: 17px; padding: 10px;
        }
        span {font-size: 20px;
          @include respond-to(media-xl) {
            font-size: 16px;
          }
          @include respond-to(media-lg) {
            font-size: 14px;
          }
        }
      }
    }
    tbody {
      tr {
        td {border-top: 1px solid #d0cece; padding: 25px 20px; font-size: 18px;
          @include respond-to(media-xl) {
            font-size: 16px;
          }
          @include respond-to(media-lg) {
            font-size: 14px; padding: 10px;
          }
          .up {color: #028855; display: inline-block; background: url(./assets/images/up_arrow.png) no-repeat 0 center; padding: 0 0 0 18px;}
          .down {color: #ff3333; display: inline-block; background: url(./assets/images/down_arrow.png) no-repeat 0 center; padding: 0 0 0 18px;}
        }
      }
    }
  }
}

.blur {filter: blur(10px); position: relative;
  &:after {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0);}
}

.checkWrapper {position:relative; width:22px; height:22px; display:inline-block; vertical-align:middle; margin:0 5px 4px 0;
  .checkInpt {opacity:0; height:22px; width:22px; position:absolute; top:0; left:0; z-index:2; margin:0;}
  .checkInpt + .bg {height:22px; width:22px; display:inline-block; position:absolute; top:0; left:0; z-index:1; border:1px solid #8b8b8b; background:#fff; border-radius: 4px;}
  .checkInpt:checked + .bg {background:#2f74b5 url(./assets/images/check_icon.png) no-repeat center; border: 1px solid #2f74b5;}

  .radioInpt {opacity:0; height:22px; width:22px; position:absolute; top:0; left:0; z-index:2; margin:0;}
  .radioInpt + .bg {height:22px; width:22px; display:inline-block; position:absolute; top:0; left:0; z-index:1; border:1px solid #8b8b8b; background:#fff; border-radius: 100%;}
  .radioInpt:checked + .bg {background:#2f74b5 url(./assets/images/radio_icon.png) no-repeat center; border: 1px solid #2f74b5;}
}

.subHd {font-size: 24px;; color: #2f75b5;
  @include respond-to(media-xl) {
      font-size: 22px;
  }
  @include respond-to(media-sm) {
      font-size: 18px;
  }
}
.dateInput {background: #fff url(./assets/images/calender_icon.png) no-repeat right 10px center;}


.pagination {display: flex; justify-content: center; padding: 20px 0 0 0;
  li {margin: 0 5px;
    a {display: inline-block; border: 1px solid #eee; padding: 4px 10px;
      &:hover {background: #2f74b5; color: #fff; border: 1px solid #2f74b5;}
    }
    &.active {
      a {background: #2f74b5; color: #fff; border: 1px solid #2f74b5;}
    }
   }
}